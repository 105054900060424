.HeaderBar {
    display: flex;
    font-size: 1.3rem;
}

.Logo {
    max-width: 50px;
    max-height: 50px;
    transition: all .2s ease-in-out;
}

.Logo:hover {
    opacity: 0.9;
    transform: scale(1.15);
}

.LogoWrapper {
    flex: 0;
}

.Stretcher {
    flex: 1;
}

.DesktopNavigationWrapper {
    display: flex;
    align-items: center;
}

.MobileNavigationWrapper {
    display: none;
}

.Link {
    display: inline-block;
    text-decoration: none;
    margin-left: 16px;
    font-weight: bold;
}

.LinkActive {
    text-decoration: underline;
}

.Link:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .Link {
        display: none;
    }

    .MobileLink {
        display: inline-block !important;
    }

    .MobileNavigationWrapper {
        display: flex;
        align-items: center;
    }

    .DesktopNavigationWrapper {
        display: none;
    }
}