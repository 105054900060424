.ContactPage {
    flex: 1;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.Content {
    display: flex;
    flex-direction: column;
}

.Header {
    font-size: 5rem;
    font-weight: bold;
}

.DescriptionWrapper {
    margin-top: 40px;
    margin-bottom: 40px;
}

.Description {
    margin-bottom: 8px;
}

.FollowUsWrapper {
    margin-bottom: 12px;
}

.FollowUs {
    font-weight: bold;
}

.SocialMediaLinkWrapper {
    margin-bottom: 8px;
}

.SocialMediaLink {
    text-decoration: none;
}

.SocialMediaLink:hover {
    text-decoration: underline;
}

.SocialMediaLinkSection {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SocialMediaLinkIcon {
    width: 32px;
    height: 32px;
}

.SocialMediaLinkSectionContent {
    margin-left: 16px;
}

@media only screen and (max-width: 500px) {
    .Row {
        flex-direction: column;
    }
    .Header {
        font-size: 2rem;
        font-weight: bold;
    }
}