.HomePage {
    flex: 1;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.Content {
    display: flex;
    flex-direction: column;
}

.Row {
    display: flex;
    flex-direction: row;
}

.Logo {
    margin-right: 40px;
}

.Header {
    font-size: 5rem;
    font-weight: bold;
}

.ReadMoreLink {
    text-decoration: none;
}

.ReadMoreLink:hover {
    text-decoration: underline;
}

.ReadMoreSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 4px;
    margin-bottom: 12px;
}

.ReadMore {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 12px;
}

.ReadMoreLinkWrapper {
    margin-top: 20px;
}

@media only screen and (max-width: 1080px) {
    .Row {
        flex-direction: column;
    }
}

@media only screen and (max-width: 700px) {
    .Logo {
        max-width: 200px;
        max-height: 180px;
    }

    .Header {
        font-size: 3rem;
        font-weight: bold;
    }

    .Logo {
        margin-bottom: 40px;
    }

    .ReadMoreLinkWrapper {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 450px) {
    .Header {
        font-size: 2rem;
    }
}