@font-face {
	font-family: 'Inter';
	font-weight: normal;
	src: local('Inter'), url(./Assets/Fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Inter';
	font-weight: bold;
	src: local('Inter'), url(./Assets/Fonts/Inter-Bold.ttf) format('truetype');
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
	display: none;
}

body {
	display: flex;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}

.App {
	height: 100%;
	width: 100%;
	min-height: 100vh;
	display: flex;
	font-family: 'Inter';
}

.AppInnerWrapper {
	flex: 1;
	padding: 48px;
	display: flex;
	flex-direction: column;
	max-width: 1080px;
	margin: 0px auto;
}