.AboutPage {
    flex: 1;
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.Content {
    display: flex;
    flex-direction: column;
}

.Row {
    display: flex;
    flex-direction: row;
}

.Header {
    font-size: 5rem;
    font-weight: bold;
}

.AboutRuchy {
    margin-right: 18px;
}

.AboutRuchy > div {
    margin-bottom: 40px;
}

.ContactUsLink {
    text-decoration: none;
}

.ContactUsLink:hover {
    text-decoration: underline;
}

.ContactUsSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 4px;
    margin-bottom: 12px;
}

.ContactUs {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 12px;
}

.CrewMember {
    margin-left: 300px;
    margin-bottom: 60px;
}

.CrewMemberHeaderWrapper {
    margin-bottom: 20px;
    text-align: right;
}

.CrewMemberPhoto {
    max-width: 300px;
    margin-right: 20px;
}

.CrewMemberUrls {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.CrewMemberUrlWrapper {
    flex: 0;
    margin-bottom: 12px;
}

.CrewMemberUrl {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
}

.CrewMemberUrlWrapper:last-child {
    margin-bottom: 0px;
}

.CrewMemberUrlSocialMediaIcon {
    max-height: 32px;
    max-width: 32px;
    height: 32px;
    width: 32px;
    margin-right: 12px;
}

.CrewMemberUrl:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 900px) {
    .AboutCircleWrapper {
        display: none;
        justify-content: center;
    }

    .CrewMember {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .Row {
        flex-direction: column;
    }

    .Header {
        font-size: 2rem;
        font-weight: bold;
    }

    .CrewMemberPhoto {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0px;
    }
    
    .CrewMember:last-child {
        margin-bottom: -20px;
    }
}