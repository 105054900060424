.FooterBar {
    display: flex;
    font-size: 1rem;
    border-top: 3px solid;
    padding-top: 12px;
}

.Right {
    flex: 1;
    display: flex;
    justify-content: right;
}

.SocialMediaLink {
    text-decoration: none;
}

.SocialMediaLinkIcon {
    height: 32px;
    width: 32px;
}

.SocialMediaLink:not(:last-child) {
    margin-right: 12px;
}

@media only screen and (max-width: 600px) {
    .FooterBar {
        font-size: 0.8rem;
    }

    .Middle {
        justify-content: left;
    }

    .Left {
        display: none;
    }
}