.Root {
    display: none;
}

.Navigation {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
}

.Overlay {
    flex: 1;
    background-color: black;
    opacity: 0.8;
}

.Stretcher {
    flex: 1;
}

.Link {
    display: inline-block;
    text-decoration: none;
    margin-left: 16px;
    font-weight: bold;
    margin: 10px 20px;
}

.Link:hover {
    text-decoration: underline;
}

.LinkActive {
    text-decoration: underline;
}

.Logo {
    max-width: 100px;
    height: 80px;
    flex: 1;
    margin: 20px 20px 10px;
}

.MobileNavigationButton {
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.MobileNavigationButton:hover {
    opacity: 0.9;
    transform: scale(1.15);
}

@media only screen and (max-width: 600px) {
    .Root {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
    }
}